import axios from 'axios';
import config from './config';

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const register = async (username, email, password) => {
  try {
    const response = await api.post('/auth/register', { username, email, password });
    return response.data;
  } catch (error) {
    console.error('Error registering:', error);
    throw error;
  }
};

export const login = async (username, password) => {
  try {
    const response = await api.post('/auth/token', new URLSearchParams({
      username,
      password
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await api.get('/auth/users/me');
    return response.data;
  } catch (error) {
    console.error('Error getting user info:', error);
    throw error;
  }
};

export const createGame = async (creatorName, rounds, roundSettings, playerId) => {
  try {
    const response = await api.post('/games', { 
      creator_name: creatorName, 
      rounds, 
      round_settings: roundSettings,
      player_id: playerId
    });
    return response.data;
  } catch (error) {
    console.error('Error creating game:', error);
    throw error;
  }
};

export const joinGame = async (playerName, gameCode, playerId) => {
  try {
    const response = await api.post('/games/join', { 
      player_name: playerName, 
      game_code: gameCode,
      player_id: playerId
    });
    return response.data;
  } catch (error) {
    console.error('Error joining game:', error);
    throw error;
  }
};

export const getGame = async (gameId) => {
  try {
    const response = await api.get(`/games/${gameId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting game:', error);
    throw error;
  }
};

export const startGame = async (gameId) => {
  try {
    const response = await api.post(`/games/${gameId}/start`);
    return response.data;
  } catch (error) {
    console.error('Error starting game:', error);
    throw error;
  }
};

export const getQuestion = async (gameId) => {
  try {
    const response = await api.get(`/games/${gameId}/question`);
    return response.data;
  } catch (error) {
    console.error('Error getting question:', error);
    throw error;
  }
};

export const submitAnswer = async (gameId, playerId, answer) => {
  try {
    const response = await api.post(`/games/${gameId}/answer`, { player_id: playerId, answer });
    return response.data;
  } catch (error) {
    console.error('Error submitting answer:', error);
    throw error;
  }
};

export const getGameResults = async (gameId) => {
  try {
    const response = await api.get(`/games/${gameId}/results`);
    return response.data;
  } catch (error) {
    console.error('Error getting game results:', error);
    throw error;
  }
};

export default api;