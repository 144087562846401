import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const usePlayerId = () => {
  const { user } = useAuth();
  const [playerId, setPlayerId] = useState(() => {
    return user ? user.id : localStorage.getItem('playerId') || null;
  });

  useEffect(() => {
    if (!playerId) {
      const newId = Math.random().toString(36).substr(2, 9);
      localStorage.setItem('playerId', newId);
      setPlayerId(newId);
    }
  }, [playerId]);

  useEffect(() => {
    if (user && user.id !== playerId) {
      setPlayerId(user.id);
    }
  }, [user, playerId]);

  return playerId;
};

export default usePlayerId;