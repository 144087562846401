import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import usePlayerId from './hooks/usePlayerId';
import MainMenu from './components/MainMenu';
import CreateGame from './components/CreateGame';
import JoinGame from './components/JoinGame';
import WaitingRoom from './components/WaitingRoom';
import GameBoard from './components/GameBoard';
import FinalResults from './components/FinalResults';
import Register from './components/Register';
import Login from './components/Login';
import OAuthCallback from './components/OAuthCallback';
import './styles/App.css';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  const playerId = usePlayerId();
  const isLocal = process.env.REACT_APP_ENVIRONMENT === 'development';

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<MainMenu />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/oauth-callback" element={<OAuthCallback />} />
            <Route path="/create" element={
              <ProtectedRoute>
                <CreateGame />
              </ProtectedRoute>
            } />
            <Route path="/join" element={<JoinGame />} />
            <Route path="/waiting/:gameId" element={
              <WebSocketProvider playerId={playerId} useWss={!isLocal}>
                <WaitingRoom />
              </WebSocketProvider>
            } />
            <Route path="/game/:gameId" element={
              <WebSocketProvider playerId={playerId} useWss={!isLocal}>
                <GameBoard />
              </WebSocketProvider>
            } />
            <Route path="/results/:gameId" element={<FinalResults />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;