import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getGame } from '../utils/api';
import usePlayerId from '../hooks/usePlayerId';
import { useWebSocket } from '../contexts/WebSocketContext';
import QuestionDisplay from './QuestionDisplay';
import RoundInfo from './RoundInfo';
import Timer from './Timer';
import PlayersList from './PlayersList';
import '../styles/GameBoard.css';

const GameBoard = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const playerId = usePlayerId();
  const [gameState, setGameState] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [allPlayersAnswered, setAllPlayersAnswered] = useState(false);
  const [players, setPlayers] = useState([]);
  const [waitingForNext, setWaitingForNext] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const { socket, error: socketError, connect, sendMessage } = useWebSocket();
  const [error, setError] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [showOptionsColors, setShowOptionsColors] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);

  useEffect(() => {
    if (gameId && playerId) {
      console.log(`Connecting to game ${gameId} for player ${playerId}`);
      connect(gameId);
    }
  }, [gameId, playerId, connect]);

  const handleSocketMessage = useCallback((eventName, data) => {
    console.log(`Received ${eventName}:`, data);

    switch (eventName) {
      case 'game_state':
        setGameState(prevState => ({
          ...prevState,
          ...data,
          state: data.state || prevState?.state
        }));
        setPlayers(data.players);
        console.log('Game state updated:', data);
        if (data.state === 'playing') {
            setCurrentQuestion(data.question);
            setTimeLeft(60);
            setAllPlayersAnswered(false);
            setWaitingForNext(false);
            setPlayerReady(false);
            setShowResults(false);
            setShowOptionsColors(false);
            setCorrectAnswer(null);
        }
        break;
      case 'game_started':
        console.log('Game started event received');
        setGameState(prevState => ({ ...prevState, state: 'playing' }));
        break;
      case 'new_question':
        console.log('New question received:', data.question);
        setCurrentQuestion(data.question);
        setGameState(prevState => ({ ...prevState, state: 'playing' }));
        setSelectedAnswer(null);
        setShowExplanation(false);
        setTimeLeft(60);
        setAllPlayersAnswered(false);
        setWaitingForNext(false);
        setPlayerReady(false);
        setShowResults(false);
        setShowOptionsColors(false);
        setCorrectAnswer(null);
        setPlayers(prevPlayers => prevPlayers.map(p => ({ ...p, answered: false, lastAnswerCorrect: null, ready: false })));
        break;
      case 'answer_result':
        if (data.type === 'answer_submitted') {
          setPlayers(prevPlayers => 
            prevPlayers.map(player => 
              player.id === data.player_id ? { ...player, answered: true } : player
            )
          );
          if (data.all_answered) {
            setAllPlayersAnswered(true);
            setWaitingForNext(true);
          }
        } else if (data.type === 'all_players_answered') {
          setAllPlayersAnswered(true);
          setShowExplanation(true);
          setShowResults(true);
          setWaitingForNext(true);
          setShowOptionsColors(true);
          setCorrectAnswer(data.correct_answer);
          setPlayers(data.scores.map(player => ({
            ...player,
            lastAnswerCorrect: player.lastAnswerCorrect
          })));
        }
        break;
      case 'waiting_for_next':
        setWaitingForNext(true);
        break;
      case 'game_ended':
        navigate(`/results/${gameId}`);
        break;
      default:
        console.log('Unknown message type:', eventName);
    }
  }, [gameId, navigate]);

  useEffect(() => {
    if (socket) {
      socket.on('game_state', (data) => handleSocketMessage('game_state', data));
      socket.on('game_started', (data) => handleSocketMessage('game_started', data));
      socket.on('new_question', (data) => handleSocketMessage('new_question', data));
      socket.on('answer_result', (data) => handleSocketMessage('answer_result', data));
      socket.on('waiting_for_next', (data) => handleSocketMessage('waiting_for_next', data));
      socket.on('all_players_ready', (data) => handleSocketMessage('all_players_ready', data));
      socket.on('game_ended', (data) => handleSocketMessage('game_ended', data));

      return () => {
        socket.off('game_state');
        socket.off('game_started');
        socket.off('new_question');
        socket.off('answer_result');
        socket.off('waiting_for_next');
        socket.off('all_players_ready');
        socket.off('game_ended');
      };
    }
  }, [socket, handleSocketMessage]);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const gameData = await getGame(gameId);
        console.log('Fetched game data:', gameData);
        setGameState(prevState => ({...prevState, ...gameData}));
        setPlayers(gameData.players);
        if (gameData.current_question) {
          setCurrentQuestion(gameData.current_question);
        }
      } catch (error) {
        console.error('Error fetching game data:', error);
        setError('Не удалось загрузить данные игры. Пожалуйста, попробуйте снова.');
      }
    };

    fetchGameData();
  }, [gameId]);

  useEffect(() => {
    if (timeLeft > 0 && currentQuestion && !showExplanation && !waitingForNext) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft, currentQuestion, showExplanation, waitingForNext]);

  const handleAnswer = useCallback((answerIndex) => {
    setSelectedAnswer(answerIndex);
  }, []);

  const handleSubmitAnswer = useCallback(() => {
    if (selectedAnswer === null) return;
    sendMessage('submit_answer', { game_id: gameId, player_id: playerId, answer: selectedAnswer });
    setPlayers(prevPlayers => 
      prevPlayers.map(player => 
        player.id === playerId ? { ...player, answered: true } : player
      )
    );
  }, [gameId, playerId, selectedAnswer, sendMessage]);

  const handleNextQuestion = useCallback(() => {
    if (!playerReady) {
      sendMessage('player_ready', { game_id: gameId, player_id: playerId });
      setPlayerReady(true);
    }
  }, [gameId, playerId, playerReady, sendMessage]);

  const currentRoundSettings = useMemo(() => {
    if (gameState?.settings?.round_settings && currentQuestion) {
      return gameState.settings.round_settings[currentQuestion.round - 1];
    }
    return null;
  }, [gameState, currentQuestion]);

  if (!gameState) {
    return <div className="loading">Загрузка...</div>;
  }

  if (gameState.state === "waiting") {
    return (
      <div className="waiting-room">
        <h2>Ожидание начала игры</h2>
        <p>Код игры: {gameState.game_code}</p>
        <PlayersList players={players} currentPlayerId={playerId} />
        {playerId === gameState.creator_id && (
          <button onClick={() => {
            console.log('Sending start game message');
            sendMessage('start_game', { game_id: gameId, player_id: playerId });
          }}>
            Начать игру
          </button>
        )}
      </div>
    );
  }

  if (gameState.state === "playing" && !currentQuestion) {
    return (
      <div className="waiting-for-question">
        <h2>Ожидание вопроса...</h2>
        <PlayersList players={players} currentPlayerId={playerId} showResults={showResults} />
      </div>
    );
  }

  if (gameState.state === "waiting_for_next") {
    return (
      <div className="waiting-for-next">
        <h2>Ожидание следующего вопроса</h2>
        <PlayersList players={players} currentPlayerId={playerId} showResults={showResults} />
        <button onClick={handleNextQuestion} disabled={playerReady} className="button next-button">
          {playerReady ? "Ожидание других игроков..." : "Продолжить"}
        </button>
      </div>
    );
  }

  if (gameState.state === "playing" && currentQuestion) {
    return (
      <div className="game-board">
        {(error || socketError) && <p className="error">{error || socketError}</p>}
        <div className="game-info">
          {currentRoundSettings && (
            <RoundInfo
              round={currentQuestion.round}
              topic={currentRoundSettings.topic}
              difficulty={currentRoundSettings.difficulty}
            />
          )}
          <Timer timeLeft={timeLeft} />
        </div>
        <div className="game-content">
          <QuestionDisplay
            question={currentQuestion.text}
            options={currentQuestion.options || []}
            onAnswer={handleAnswer}
            onSubmit={handleSubmitAnswer}
            selectedAnswer={selectedAnswer}
            correctAnswer={correctAnswer}
            explanation={currentQuestion.explanation}
            onNextQuestion={handleNextQuestion}
            showExplanation={showExplanation}
            allPlayersAnswered={allPlayersAnswered}
            isAnswerSubmitted={players.find(p => p.id === playerId)?.answered}
            waitingForNext={waitingForNext}
            playerReady={playerReady}
            showOptionsColors={showOptionsColors}
          />
          <PlayersList 
            players={players} 
            currentPlayerId={playerId} 
            showResults={showResults}
          />
        </div>
      </div>
    );
  }

  return <div className="loading">Загрузка игры...</div>;
};

export default React.memo(GameBoard);