import React from 'react';
import '../styles/PlayersList.css';

const PlayersList = ({ players, currentPlayerId, showResults }) => {
  return (
    <div className="players-list">
      <h3>Игроки:</h3>
      <ul>
        {players.map((player) => (
          <li key={player.id} className={player.id === currentPlayerId ? 'current-player' : ''}>
            {player.name} 
            {player.answered && !showResults && <span className="answered-indicator">✓</span>}
            {showResults && (
              <span className={`result-indicator ${player.lastAnswerCorrect ? 'correct' : 'incorrect'}`}>
                {player.lastAnswerCorrect ? '✅' : '❌'}
              </span>
            )}
            <span className="player-score">{player.score}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayersList;