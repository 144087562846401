import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUserInfo } from '../utils/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getUserInfo().then(userData => {
        setUser(userData);
      }).catch(error => {
        console.error('Failed to get user info:', error);
        localStorage.removeItem('token');
      });
    }
  }, []);

  const login = async (token) => {
    localStorage.setItem('token', token);
    try {
      const userData = await getUserInfo();
      setUser(userData);
    } catch (error) {
      console.error('Failed to get user info:', error);
      localStorage.removeItem('token');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);