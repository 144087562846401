import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RoundSettings from './RoundSettings';
import { createGame } from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import '../styles/CreateGame.css';

const CreateGame = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [rounds, setRounds] = useState(1);
  const [roundSettings, setRoundSettings] = useState([{ topic: '', difficulty: 'medium' }]);
  const [playerName, setPlayerName] = useState(user ? user.username : '');
  const [error, setError] = useState('');

  const handleCreateGame = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a game.');
      return;
    }
    try {
      const { game_id, game_code } = await createGame(playerName, rounds, roundSettings, user.id);
      navigate(`/waiting/${game_id}`, { state: { gameCode: game_code } });
    } catch (error) {
      setError('Failed to create game. Please try again.');
    }
  };

  const handleRoundSettingsChange = (index, setting) => {
    const newSettings = [...roundSettings];
    newSettings[index] = setting;
    setRoundSettings(newSettings);
  };

  const handleRoundsChange = (e) => {
    const newRounds = parseInt(e.target.value);
    setRounds(newRounds);
    setRoundSettings(prevSettings => {
      const newSettings = [...prevSettings];
      if (newRounds > prevSettings.length) {
        for (let i = prevSettings.length; i < newRounds; i++) {
          newSettings.push({ topic: '', difficulty: 'medium' });
        }
      } else if (newRounds < prevSettings.length) {
        newSettings.splice(newRounds);
      }
      return newSettings;
    });
  };

  return (
    <div className="create-game fade-in">
      <h2>Создание игры</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleCreateGame}>
        <input
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder="Ваше имя"
          required
        />
        <select value={rounds} onChange={handleRoundsChange}>
          {[1, 2, 3, 4, 5].map(num => (
            <option key={num} value={num}>{num} раунд(ов)</option>
          ))}
        </select>
        {roundSettings.map((setting, index) => (
          <RoundSettings
            key={index}
            setting={setting}
            onChange={(setting) => handleRoundSettingsChange(index, setting)}
          />
        ))}
        <button type="submit" className="button">Создать игру</button>
      </form>
    </div>
  );
};

export default CreateGame;