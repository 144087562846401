import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as apiLogin } from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import config from '../utils/config';
import '../styles/Auth.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const data = await apiLogin(username, password);
      login(data.access_token);
      
      if (formRef.current) {
        const passwordInput = formRef.current.querySelector('input[type="password"]');
        if (passwordInput) {
          passwordInput.type = 'text';
          setTimeout(() => {
            passwordInput.type = 'password';
            navigate('/');
          }, 0);
        }
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred during login');
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${config.apiUrl}/oauth/login/google`;
  };

  const handleRegistration = () => {
    navigate('/register');
  }

  return (
    <div className="auth-container">
      <h2>Вход</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} ref={formRef}>
        <input
          type="text"
          name="username"
          placeholder="Имя"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          autoComplete="username"
        />
        <input
          type="password"
          name="current-password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete="current-password"
        />
        <button type="submit" className="auth-button">
          <div className="auth-button-content">
            <span>Войти</span>
          </div>
        </button>
      </form>
        <button onClick={handleRegistration} className="auth-button">
          <div className="auth-button-content">
            <span>Зарегистрироваться</span>
          </div>
        </button>
      <div className="social-login">
        <button onClick={handleGoogleLogin} className="gsi-material-button">
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: "block"}}>
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span className="gsi-material-button-contents">Continue with Google</span>
            <span style={{display: "none"}}>Continue with Google</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Login;