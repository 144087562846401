import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      login(token);
      navigate('/', { replace: true });
    } else {
      console.error('No token received from OAuth');
      navigate('/login', { replace: true });
    }
  }, [location, login, navigate]);

  return <div>Processing authentication...</div>;
};

export default OAuthCallback;