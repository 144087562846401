// Timer.js
import React from 'react';
import '../styles/Timer.css';

const Timer = ({ timeLeft }) => {
  return (
    <div className="timer">
      <div className="timer-bar" style={{ width: `${(timeLeft / 60) * 100}%` }}></div>
      <span className="timer-text">{timeLeft} сек</span>
    </div>
  );
};

export default Timer;