import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { io } from 'socket.io-client';
import config from '../utils/config';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, playerId }) => {
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const socketRef = useRef(null);

  const connect = useCallback((gameId) => {
    if (!gameId || socketRef.current) return;

    console.log(`Attempting to connect to Socket.IO for game ${gameId}`);
    const newSocket = io(config.socketUrl, {
      path: '/socket.io',
      transports: ['websocket'],
      query: { gameId, playerId },
      withCredentials: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    newSocket.on('connect', () => {
      console.log('Socket.IO connection established');
      setError(null);
      newSocket.emit('join_game', { game_id: gameId, player_id: playerId });
    });

    newSocket.on('connect_error', (err) => {
      console.error('Socket.IO connection error:', err);
      setError('Произошла ошибка соединения.');
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Socket.IO disconnected:', reason);
      if (reason === 'io server disconnect') {
        // Попытка переподключения при отключении сервера
        newSocket.connect();
      }
    });

    newSocket.onAny((eventName, ...args) => {
      console.log(`Received event "${eventName}":`, args);
    });

    socketRef.current = newSocket;
    setSocket(newSocket);

    return () => {
      console.log('Cleaning up socket connection');
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [playerId]);

  const sendMessage = useCallback((eventName, data) => {
    if (socketRef.current && socketRef.current.connected) {
      console.log(`Sending ${eventName} to server:`, data);
      socketRef.current.emit(eventName, data);
    } else {
      console.error('Socket is not connected');
      setError('Соединение не установлено. Попробуйте обновить страницу.');
    }
  }, []);

  const value = {
    socket: socketRef.current,
    error,
    connect,
    sendMessage,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);