import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getGame } from '../utils/api';
import usePlayerId from '../hooks/usePlayerId';
import { useWebSocket } from '../contexts/WebSocketContext';
import '../styles/WaitingRoom.css';

const WaitingRoom = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const playerId = usePlayerId();
  const [players, setPlayers] = useState([]);
  const [gameSettings, setGameSettings] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [gameCode, setGameCode] = useState(location.state?.gameCode || 'N/A');
  const { socket, error, connect, sendMessage } = useWebSocket();

  useEffect(() => {
    if (gameId && playerId) {
      console.log(`Connecting to game ${gameId} for player ${playerId}`);
      connect(gameId);
    }
  }, [gameId, playerId, connect]);

  const handleSocketMessage = useCallback((eventName, data) => {
    console.log(`Received ${eventName} in WaitingRoom:`, data);
    switch (eventName) {
      case 'game_started':
        console.log('Game started, navigating to game board');
        navigate(`/game/${gameId}`);
        break;
      case 'player_joined':
        setPlayers(prevPlayers => [...prevPlayers, data.player]);
        break;
      case 'game_state':
        setPlayers(data.players);
        setGameSettings({
          rounds: data.settings.rounds,
          roundSettings: data.settings.round_settings
        });
        setIsCreator(data.creator_id === playerId);
        setGameCode(data.game_code);
        if (data.state === 'PLAYING') {
          console.log('Game is already in progress, navigating to game board');
          navigate(`/game/${gameId}`);
        }
        break;
      default:
        console.log('Unknown event:', eventName);
    }
  }, [gameId, navigate, playerId]);

  useEffect(() => {
    if (socket) {
      socket.on('game_started', (data) => handleSocketMessage('game_started', data));
      socket.on('player_joined', (data) => handleSocketMessage('player_joined', data));
      socket.on('game_state', (data) => handleSocketMessage('game_state', data));

      return () => {
        socket.off('game_started');
        socket.off('player_joined');
        socket.off('game_state');
      };
    }
  }, [socket, handleSocketMessage]);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const gameData = await getGame(gameId);
        setPlayers(gameData.players);
        setGameSettings({
          rounds: gameData.rounds,
          roundSettings: gameData.round_settings
        });
        setIsCreator(gameData.creator === playerId);
        setGameCode(gameData.code);
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    };

    fetchGameData();
  }, [gameId, playerId]);

  const handleStartGame = async () => {
    try {
      console.log('Sending start_game message');
      sendMessage('start_game', { game_id: gameId, player_id: playerId });
    } catch (error) {
      console.error('Error starting game:', error);
    }
  };

  return (
    <div className="waiting-room fade-in">
      <h2>Комната ожидания</h2>
      {error && (
        <div className="error-container">
          <p className="error">{error}</p>
          <button onClick={() => connect(gameId)} className="button">Переподключиться</button>
        </div>
      )}
      <div className="game-info">
        <p>Код игры: <span className="highlight">{gameCode}</span></p>
      </div>
      <div className="players-list">
        <h3>Игроки:</h3>
        <ul>
          {players.map((player, index) => (
            <li key={`${player.id}-${index}`}>{player.name}</li>
          ))}
        </ul>
      </div>
      {gameSettings && (
        <div className="game-settings">
          <h3>Настройки игры:</h3>
          <p>Количество раундов: {gameSettings.rounds}</p>
          {gameSettings.roundSettings.map((setting, index) => (
            <p key={index}>
              Раунд {index + 1}: {setting.topic} ({setting.difficulty})
            </p>
          ))}
        </div>
      )}
      {isCreator && (
        <button className="button start-game" onClick={handleStartGame}>Начать игру</button>
      )}
    </div>
  );
};

export default WaitingRoom;