const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development' || 
                      process.env.NODE_ENV === 'development' ||
                      window.location.hostname === 'localhost' ||
                      window.location.hostname === '127.0.0.1';

const getApiUrl = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  if (isDevelopment) {
    return `${window.location.protocol}//${window.location.hostname}:8000/api`;
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return 'https://quizverse.ru/api';
  } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return 'https://dev.quizverse.ru/api';
  }
};

const getSocketUrl = () => {
  if (process.env.REACT_APP_SOCKET_URL) {
    return process.env.REACT_APP_SOCKET_URL;
  }

  if (isDevelopment) {
    return `${window.location.protocol}//${window.location.hostname}:8000`;
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return 'https://quizverse.ru';
  } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return 'https://dev.quizverse.ru';
  }
};

const config = {
  apiUrl: getApiUrl(),
  socketUrl: getSocketUrl(),
  isDevelopment: isDevelopment
};

console.log('Current environment:', isDevelopment ? 'development' : process.env.REACT_APP_ENVIRONMENT);
console.log('API URL:', config.apiUrl);
console.log('Socket URL:', config.socketUrl);

export default config;