import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getGameResults } from '../utils/api';
import '../styles/FinalResults.css';

const FinalResults = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [winner, setWinner] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await getGameResults(gameId);
        setResults(data.results);
        setWinner(data.winner);
      } catch (error) {
        console.error('Error fetching game results:', error);
        setError('Failed to fetch game results. The game may not be finished yet.');
      }
    };

    fetchResults();
  }, [gameId]);

  const handleReturnToMenu = () => {
    navigate('/');
  };

  if (error) {
    return (
      <div className="final-results fade-in">
        <h2>Error</h2>
        <p>{error}</p>
        <button className="button" onClick={handleReturnToMenu}>
          Return to Main Menu
        </button>
      </div>
    );
  }

  return (
    <div className="final-results fade-in">
      <h2>Результаты игры</h2>
      {winner && <h3>Победитель: {winner}</h3>}
      <table className="results-table">
        <thead>
          <tr>
            <th>Место</th>
            <th>Игрок</th>
            <th>Очки</th>
          </tr>
        </thead>
        <tbody>
          {results.map((player, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{player.name}</td>
              <td>{player.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="button" onClick={handleReturnToMenu}>
        Вернуться в главное меню
      </button>
    </div>
  );
};

export default FinalResults;