import React, { useState, useEffect } from 'react';
import '../styles/Question.css';

const QuestionDisplay = ({ 
  question, 
  options, 
  onAnswer, 
  onSubmit,
  selectedAnswer, 
  correctAnswer, 
  explanation, 
  onNextQuestion, 
  showExplanation,
  allPlayersAnswered,
  isAnswerSubmitted,
  waitingForNext,
  playerReady,
  showOptionsColors
}) => {
  const [pressedButton, setPressedButton] = useState(null);

  // Сброс pressedButton при изменении вопроса или связанных состояний
  useEffect(() => {
    setPressedButton(null);
  }, [question, showOptionsColors, waitingForNext]);

  const getButtonClass = (index) => {
    let className = 'option-button';
    if (showOptionsColors) {
      if (index === correctAnswer) {
        className += ' correct';
      } else if (index === selectedAnswer && selectedAnswer !== correctAnswer) {
        className += ' incorrect';
      }
    } else if (index === pressedButton) {
      className += ' pressed';
    }
    return className;
  };

  const handleButtonPress = (index) => {
    setPressedButton(index);
    onAnswer(index);
  };

  return (
    <div className="question-display fade-in">
      <div className="question-wrapper">
        <h2>{question}</h2>
      </div>
      <div className={`options options-${options.length}`}>
        {options.map((option, index) => (
          <button 
            key={index} 
            className={getButtonClass(index)}
            onClick={() => handleButtonPress(index)}
            disabled={isAnswerSubmitted || waitingForNext}
          >
            {option}
          </button>
        ))}
      </div>
      {!isAnswerSubmitted && !waitingForNext ? (
        <button 
          className="button submit-answer" 
          onClick={onSubmit}
          disabled={pressedButton === null}
        >
          Отправить
        </button>
      ) : (
        <button 
          className="button continue" 
          onClick={onNextQuestion}
          disabled={!allPlayersAnswered || playerReady}
        >
          {!allPlayersAnswered ? 'Ожидание других игроков...' : 
           playerReady ? 'Ожидание других игроков...' : 'Продолжить'}
        </button>
      )}
      {showExplanation && (
        <div className="explanation-box slide-in">
          <h3>Пояснение:</h3>
          <p>{explanation}</p>
        </div>
      )}
    </div>
  );
};

export default QuestionDisplay;