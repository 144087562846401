// RoundInfo.js
import React from 'react';
import '../styles/RoundInfo.css';

const RoundInfo = ({ round, topic, difficulty }) => {
  return (
    <div className="round-info">
      <h2>Раунд {round}</h2>
      <p>Тема: {topic}</p>
      <p>Сложность: {difficulty}</p>
    </div>
  );
};

export default RoundInfo;