import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import config from './utils/config';

// Функция для принудительного использования HTTPS
const forceHttps = () => {
  if (
    !config.isDevelopment &&
    window.location.protocol !== 'https:'
  ) {
    window.location.href = window.location.href.replace('http:', 'https:');
  }
};

// Вызываем функцию при загрузке приложения
forceHttps();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);