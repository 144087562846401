import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { joinGame } from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import '../styles/JoinGame.css';

const JoinGame = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [playerName, setPlayerName] = useState(user ? user.username : '');
  const [gameCode, setGameCode] = useState('');
  const [error, setError] = useState('');

  const handleJoinGame = async (e) => {
    e.preventDefault();
    try {
      const { game_id } = await joinGame(playerName, gameCode, user ? user.id : null);
      navigate(`/waiting/${game_id}`);
    } catch (error) {
      setError('Failed to join game. Please check the game code and try again.');
    }
  };

  return (
    <div className="join-game fade-in">
      <h2>Присоединиться к игре</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleJoinGame}>
        <input
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder="Ваше имя"
          required
        />
        <input
          type="text"
          value={gameCode}
          onChange={(e) => setGameCode(e.target.value)}
          placeholder="Код игры"
          required
        />
        <button type="submit" className="button">Присоединиться</button>
      </form>
    </div>
  );
};

export default JoinGame;