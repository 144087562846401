import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../styles/MainMenu.css';

const MainMenu = () => {
  const { user, logout } = useAuth();

  return (
    <div className="main-menu fade-in">
      <h1>QuizVerse</h1>
      {user && (
        <div className="user-info">
          <h2>Добро пожаловать, {user.username}!</h2>
        </div>
      )}
      <div className="button-container">
        {user ? (
          <>
            <Link to="/create" className="button">Создать игру</Link>
            <Link to="/join" className="button">Присоединиться к игре</Link>
            <button onClick={logout} className="button">Выйти</button>
          </>
        ) : (
          <>
            <Link to="/login" className="button">Войти</Link>
            <Link to="/join" className="button">Присоединиться к игре</Link>
          </>
        )}
      </div>
      <footer className="footer">
        <p>By Andrew G.</p>
        <p>Powered by Anthropic</p>
      </footer>
    </div>
  );
};

export default MainMenu;