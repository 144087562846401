import React from 'react';
import '../styles/RoundSettings.css';

const RoundSettings = ({ setting, onChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...setting, [name]: value });
  };

  return (
    <div className="round-settings">
      <input
        type="text"
        name="topic"
        value={setting.topic}
        onChange={handleChange}
        placeholder="Тема раунда"
        required
      />
      <select name="difficulty" value={setting.difficulty} onChange={handleChange}>
        <option value="easy">Легкая</option>
        <option value="medium">Средняя</option>
        <option value="hard">Сложная</option>
      </select>
    </div>
  );
};

export default RoundSettings;